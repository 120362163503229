import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import btnArrow from "../assets/img/section-btn-arrow.webp";
import alterationIconTwo from "../assets/img/alteration-icon-2.webp";
import alterationIconOne from "../assets/img/alteration-icon-1.webp";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import useCartStore from "../api/cart";

const pathURL = process.env.REACT_APP_API_URL;

const SweaterServicesFilteration = (services) => {
  const priorityMap = {
    'SWEATER - FIX ONE 1/4" HOLE': 1,
    'SWEATER - FIX ONE 1/4-1/2" HOLE': 2,
    'SWEATER - FIX ONE 1/2-1" HOLE': 3,
    'SWEATER - FIX ADDITIONAL 1/4" HOLE': 4,
    'SWEATER - FIX ADDITIONAL 1/4-1/2" HOLE': 5,
    'SWEATER - FIX ADDITIONAL 1/2-1" HOLE': 6,
    'SWEATER - FIX RIP ALONG THE SEAM (< 4 IN)': 7,
    'SWEATER - FIX RIP ALONG THE SEAM (> 4 IN)': 8,
    "SWEATER - DEPILL": 9
  };

  return services.sort((a, b) => {
    const aName = a.name.toUpperCase();
    const bName = b.name.toUpperCase();

    const aPriority = priorityMap[aName] || 100; // Default to high number if not found
    const bPriority = priorityMap[bName] || 100; // Default to high number if not found

    return aPriority - bPriority;
  });
}

function Alteration() {
  const location = useLocation();
  const selectedType = location?.state?.selectedItem ? location?.state?.selectedItem?.name : localStorage.getItem("selectedItem")
  const { cartItems, updateCartItem } = useCartStore();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const fetchCode = async () => {
    const { data } = await axios.post(`${pathURL}service/getservicebyitem`, {
      text: location?.state?.selectedItem?.name.trim() || selectedType?.trim(),
    });
    return data;
  };
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["fetchService"],
    queryFn: fetchCode,
  });

  const [selectedPricingPlan, setselectedPricingPlan] = useState("");

  const [isOpen, setIsOpen] = useState(true);
  const [isOpen1, setIsOpen1] = useState(true);

  const toggleBox = () => {
    setIsOpen(!isOpen);
    setIsOpen1(!isOpen1);
  };

  const [selectedPlan] = useState(() => {
    const savedPlan = localStorage.getItem("selectedPlan");
    return savedPlan || "";
  });

  const [selectedTask, setSelectedTask] = useState(() => {
    const savedTask = localStorage.getItem("task");
    return savedTask || "";
  });
  const [pinVideo, setPinVideo] = useState();
  const [selectedOption, setSelectedOption] = useState(() => {
    const savedOption = localStorage.getItem("option");
    return savedOption || "";
  });

  const [selectedOptionPrice, setSelectedOptionPrice] = useState(() => {
    const savedOption = localStorage.getItem("optionprice");
    return savedOption || "";
  });

  const handleTaskSelection = (task) => {
    setSelectedTask(task);
  };

  const [services, setservices] = useState([]);

  const handleOptionSelection = (item, data) => {
    if (services && selectedOption === item?.name) {
      setSelectedOption(null);
      setservices([]);
      setSelectedOptionPrice("");
      setPinVideo();
    } else {
      setSelectedOption(item?.name);
      setSelectedOptionPrice(
        localStorage.getItem("SelectedPlan") == "Premium" ||
          location?.state?.selectedItem?.pricing_plan == "Premium"
          ? item?.premium_price
          : item?.standard_price
      );
      setservices([data]);
      setPinVideo(item.pin_item_video);
    }
  };

  const navigate = useNavigate();

  const handleApply = () => {
    const storedTask = localStorage.getItem("task");
    const storedOption = localStorage.getItem("option");

    if ((selectedTask, selectedOption)) {
      updateCartItem(cartItems[0].id, {
        services: [
          ...(cartItems[0]?.services || []).map((item) => {
            if (item.selectedTask === storedTask && item.selectedOption === storedOption) {
              return {
                ...item,
                selectedOption: selectedOption,
                selectedService: selectedTask,
                item_details: JSON.parse(localStorage.getItem("selectedItems")),
              };
            }
            return item;
          }),
          ...((cartItems[0]?.services || []).findIndex((item) => item.selectedTask === storedTask && item.selectedOption === storedOption) === -1
            ? services.map((item) => ({
              ...item,
              selectedOption: selectedOption,
              selectedService: selectedTask,
              item_details: JSON.parse(localStorage.getItem("selectedItems")),
            })) : []),
        ]
      });
      if (selectedTask === "Alteration") {
        localStorage.setItem("task", selectedTask);
        localStorage.setItem("option", selectedOption);
        localStorage.setItem("optionprice", selectedOptionPrice);
        localStorage.setItem("pinVideo", pinVideo);
        navigate("/perfect-fit", { state: { selectedTask, selectedOption, data: location.state } });
      } else {
        localStorage.setItem("task", selectedTask);
        localStorage.setItem("option", selectedOption);
        localStorage.setItem("pinVideo", pinVideo);
        localStorage.setItem("optionprice", selectedOptionPrice);
        navigate("/know-more", { state: { selectedTask, selectedOption, data: location.state } });
      }
    }
  };

  useEffect(() => {
    const filteredServices = cartItems[0]?.services?.filter(
      (service) => service?.name != localStorage.getItem("option")
    );
    updateCartItem(cartItems[0].id, {
      services: filteredServices,
    });
  }, []);
  useEffect(() => {
    if (location?.state?.addNewService) {
      setselectedPricingPlan(location?.state?.selectedItem?.description);
    }
    setselectedPricingPlan(cartItems[0]?.pricing_plan);
  }, [cartItems]);


  const renderServiceItem = (item, index, pricingPlan) => {
    if ((item?.is_premium && pricingPlan === "Premium") || (item?.is_standard && pricingPlan === "Standard")) {
      return (
        <li key={index}>
          <button
            onClick={() => handleOptionSelection(item, item)}
            className={selectedOption === item?.name ? "item-selected" : ""}
          >
            {item?.name}
          </button>
        </li>
      );
    }
    return null;
  };

  const renderServices = (services) => {
    return services?.map((item, index) => {
      let pricingPlan = location?.state?.addNewService ? location?.state?.selectedItem?.pricing_plan : location?.state?.selectedPlan;
      pricingPlan = pricingPlan || localStorage.getItem("SelectedPlan")
      if (item.service_type === true && selectedTask === "Alteration") {
        let itemdata1 = renderServiceItem(item, index, pricingPlan);
        return renderServiceItem(item, index, pricingPlan);
      } else if (item.service_type === false && selectedTask === "Repairing") {
        return renderServiceItem(item, index, pricingPlan);
      }
      return null;
    });
  };

  const selectedItem = location?.state?.selectedItem || localStorage.getItem("selectedItem");

  return (
    <section className="items-section same-bg-section relative">
      <div className="container padding-x-all">
        <div className="alteration-box items-box sec-com-padding flex-box justify-between align-center">
          <div
            className={`show-box ${!isOpen ? "open mobile-none" : "mobile-none"
              }`}
            onClick={toggleBox}
          >
            <div className="show-box-list">
              <p className="show-box-para">Your items</p>
              <h4 className="show-box-answer">
                {selectedType}
              </h4>
            </div>

            <div className="show-box-list">
              <p className="show-box-para">Services</p>
              <h4 className="show-box-answer">
                {location?.state?.addNewService
                  ? location?.state?.selectedItem?.pricing_plan
                  : localStorage.getItem("SelectedPlan")}
              </h4>
            </div>
            <h4 className="show-box-answer">{selectedTask || "-"}</h4>
            <p className="show-box-para">Task Detail</p>
            <div className="service-job-detail-box">
              <p className="show-box-para">{selectedOption || "-"}</p>
              {selectedOptionPrice != "" && (
                <div className="text-box">
                  <h4 className="show-box-answer">${selectedOptionPrice}</h4>
                </div>
              )}
            </div>
          </div>

          <div
            className={`show-box ${!isOpen1 ? "open desktop-none" : "desktop-none"
              }`}
            onClick={toggleBox}
          >
            <div className="show-box-list">
              <p className="show-box-para">Your items</p>
              <h4 className="show-box-answer">
                {selectedType}
              </h4>
            </div>

            <div className="show-box-list">
              <p className="show-box-para">Services</p>
              <h4 className="show-box-answer">
                {location?.state?.addNewService
                  ? location?.state?.selectedItem?.pricing_plan
                  : localStorage.getItem("SelectedPlan")}
              </h4>
            </div>

            <div className="show-box-last-list">
              <p className="show-box-para">Task Detail</p>
              <h4 className="show-box-answer">{selectedTask || "-"}</h4>
              <div className="service-job-detail-box">
                <p className="show-box-para">{selectedOption || "-"}</p>
                {selectedOptionPrice != "" && (
                  <div className="text-box">
                    <h4 className="show-box-answer">${selectedOptionPrice}</h4>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="item-select-box">
            <h2>
              Would you like your {selectedType ? selectedType : "item"} altered
              or repaired?
            </h2>
            <p className="para">
              Select one service, if you require another you can add this at a
              later stage.
            </p>

            <div className="alteration-select-box wears-box flex-box justify-between">
              <div className="icon-main-box">
                <button
                  onClick={() => handleTaskSelection("Alteration")}
                  className={
                    selectedTask === "Alteration"
                      ? "icon-box item-selected"
                      : "icon-box"
                  }
                >
                  <img src={alterationIconOne} alt="" />
                  <h6>Altered</h6>
                </button>
                <button
                  onClick={() => handleTaskSelection("Repairing")}
                  className={
                    selectedTask === "Repairing"
                      ? "icon-box item-selected"
                      : "icon-box"
                  }
                >
                  <img src={alterationIconTwo} alt="" />
                  <h6>Repair</h6>
                </button>
              </div>
              <div className="text-box">
                {!isLoading && (
                  <>
                    {selectedTask != "" && (
                      <p className="para">Select option...</p>
                    )}
                    <ul className="items-wears-listing scroll-wrapper">
                      {(selectedItem == "SWEATER" || selectedItem?.name == "SWEATER") &&
                        renderServices(SweaterServicesFilteration(data))}
                      {(selectedItem != "SWEATER" && selectedItem?.name != "SWEATER") &&
                        <>
                          {console.log(selectedItem)}
                          {renderServices(data)}
                        </>
                      }
                    </ul>
                  </>
                )}
              </div>
            </div>
            <button
              className={`${selectedTask && selectedOption
                ? "section-main-btn"
                : "section-main-btn1"
                }`}
              type="button"
              disabled={selectedTask && selectedOption ? false : true}
              onClick={handleApply}
            >
              {" "}
              Fix/Fit it
              <span>
                <img src={btnArrow} alt="" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Alteration;
