import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import iconOne from "../assets/img/pincode-icon-1.webp";
import iconTwo from "../assets/img/pincode-icon-2.webp";
import iconThree from "../assets/img/pincode-icon-3.webp";
import axios from "axios";
import useCartStore from "../api/cart";
import { QueryClient, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";

const pathURL = process.env.REACT_APP_API_URL;

const fetchCode = async ({ queryKey }) => {
  const code = queryKey[1];
  const { data } = await axios.get(`${pathURL}zipcodes/getone/${code}`);
  return data;
};

function PincodeScreen() {
  const { addToCart, cartItems, updateCartItem } = useCartStore();
  const [error, setError] = useState("");

  const [pincode, setPincode] = useState();

  const validatePincode = (pincode) => {
    const pincodePattern = /^\d{5}(?:[-\s]\d{4})?$/; // US zip code pattern: 12345 or 12345-6789
    return pincodePattern.test(pincode);
  };

  const navigate = useNavigate();

  const handleSubmit = async () => {
    // if (validatePincode(pincode)) {
    setError("");
    const response = await axios.get(`${pathURL}zipcodes/getone/${pincode}`);
    const postdata = {
      zipcodeData: response.data,
      zipcode: response.data.zipcode,
    };
    if (response.status === 200 && response.data && response.data != null) {
      if (cartItems[0]?.zipcode === pincode) {
        updateCartItem(cartItems[0]?.id, {
          zipcodeData: response.data,
          zipcode: response.data.zipcode,
        });
      } else {
        addToCart(postdata);
      }
      navigate("/types", { state: { pincode } });
    } else {
      window.open(`https://www.thefxry.com/zip-code-not-found`, "_self");
      // toast.error("We are not servicable at this zipcode!")
    }
    // } else {
    //   setError('Invalid pincode. Please enter a 6-digit pincode.');
    // }
  };
  let [zipcode1, setzipcode] = useState(null);
  const location = useLocation();
  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    let zipcode = searchParams.get("zip");
    if (zipcode) {
      setzipcode(zipcode);
      setPincode(zipcode);
    } else {
      setPincode(cartItems[0]?.zipcode);
    }
    if (localStorage.getItem("addnewservice")) {
      navigate("/alteration");
    }
    if (localStorage.getItem("addnewitem")) {
      navigate("/types", {
        state: { addNewItem: true },
      });
    }
    // console.log(localStorage.getItem("addnewitem"))
    if (
      cartItems[0]?.zipcode != undefined &&
      !localStorage.getItem("addnewitem")
    ) {
      console.log("shivi");
      navigate("/types");
    }
    if (
      cartItems[0]?.itemsData?.length >= 1 &&
      cartItems[0]?.services?.length > 0 &&
      !localStorage.getItem("addnewitem") &&
      !localStorage.getItem("addnewservice")
    ) {
      navigate("/item-detail");
    }
    if (zipcode1) {
      handleSubmit();
    }
  }, [zipcode1]);

  const handlePincodeChange = (e) => {
    const newPincode = e.target.value;

    // Only allow numeric input and hyphen
    if (/^[0-9-]*$/.test(newPincode)) {
      setPincode(newPincode);

      if (newPincode === "" || validatePincode(newPincode)) {
        setError("");
      } else {
        setError(
          "Invalid US zip code. Please enter a valid 5-digit or 9-digit zip code."
        );
      }
    }
  };

  // useEffect(() => {
  //   const fetchExcelOrder = async () => {
  //     const res = await axios.get(`${pathURL}order/allorderexcelsheet`);
  //     console.log(res.data);
  //   };
  //   fetchExcelOrder();
  // }, []);

  return (
    <section className="pincode-section relative">
      <div className="container padding-x-all">
        <div className="pincode-box sec-com-padding ">
          <h1 className="relative">
            Stitch it, don't ditch it. <br />
            Door-to-door tailoring.
          </h1>

          <ul className="pincode-usp-listing flex-box">
            <li className="flex-box">
              <div className="img-box">
                <img src={iconOne} alt="" />
              </div>
              <div className="text-box">
                <p>
                  <strong>Two Week </strong> <br />
                  Turnaround
                </p>
              </div>
            </li>
            <li className="flex-box">
              <div className="img-box">
                <img src={iconTwo} alt="" />
              </div>
              <div className="text-box">
                <p>
                  <strong>Quality</strong> <br />
                  Craftsmanship
                </p>
              </div>
            </li>
            <li className="flex-box">
              <div className="img-box">
                <img src={iconThree} alt="" />
              </div>
              <div className="text-box">
                <p>
                  <strong>Customer </strong> <br />
                  Convenience
                </p>
              </div>
            </li>
          </ul>

          <form className="pincode-form" onSubmit={(e) => e.preventDefault()}>
            <input
              type="tel"
              max={5}
              maxLength={5}
              value={pincode}
              onChange={handlePincodeChange}
              placeholder="Enter your ZIP code"
            />
            <button
              className="pincode-form-btn"
              type="button"
              onClick={handleSubmit}
            >
              Get Started
            </button>
            {error && (
              <p
                className="para"
                style={{ marginTop: "4px", fontSize: "1rem", color: "black" }}
              >
                {error}
              </p>
            )}
          </form>
        </div>
      </div>
    </section>
  );
}

export default PincodeScreen;
